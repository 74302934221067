import Vue from 'vue'
import App from './App.vue'
import vueCustomElement from 'vue-custom-element'
import 'document-register-element/build/document-register-element';

Vue.use(vueCustomElement);

Vue.config.productionTip = false

new Vue({
  render: h => h(App)
}).$mount('#app');

Vue.customElement('tworoomz-widget', App);